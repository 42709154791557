// Constants
import { ADDONS } from '@/constants'
// Components
import CardContainer from '@/components/ui/CardContainer'
import TakeAwayOrderDataTableItem from '../../components/elements/orders/TakeAwayOrderDataTableItem'
import VuetifyDataTable from '@/components/ui/VuetifyDataTable'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Servicios
import { getOrdersByDateAndPlaceById } from '../../services/order'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import takeAwayMixin from '../../mixins/takeAwayMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapGetters } from 'vuex'
// Utils
import { get, isNil } from 'lodash'
// filters
import { formatDate } from '@/filters'

export default {
  name: 'TakeAwayOrdersHistory',
  components: {
    CardContainer,
    TakeAwayOrderDataTableItem,
    VuetifyDataTable,
    VuetifyToolBar
  },
  mixins: [addonsMixin, takeAwayMixin, uiMixin],
  data() {
    return {
      // Table
      headers: [
        {
          text: 'Pedido',
          value: 'orderId'
        },
        {
          text: 'Nombre',
          value: 'name'
        },
        {
          text: 'Teléfono',
          value: 'phone'
        },
        {
          text: 'Tipo',
          value: 'type'
        },
        {
          text: 'Fecha',
          value: 'orderTimestamp'
        },
        {
          text: 'Estado',
          align: 'center',
          value: 'status'
        },
        {
          text: 'Acciones',
          align: 'center',
          sortable: false
        }
      ],
      // Others
      dates: [],
      items: [],
      processingRequest: false,
      showDatePicker: false
    }
  },
  computed: {
    ...mapGetters('place', ['placeData']),
    ...mapGetters('takeAway', ['takeAwayHasMenu']),
    /**
     * Fechas formateadas para el datepicker
     *
     * @return {string}
     */
    datesFormatted() {
      if (!isNil(this.dates) && this.dates.length === 2) {
        return `${formatDate(this.dates[0], 'DD/MM/YYYY')} - ${formatDate(
          this.dates[1],
          'DD/MM/YYYY'
        )}`
      }
      return
    },
    /**
     * Opciones para el "v-date-picker"
     *
     * @return {object}
     */
    vDatePickerOptions() {
      const takeAwayConfig = get(this.placeAddonsSetupByUser, ADDONS.takeAway, {})
      const preorderEnabled = get(takeAwayConfig, 'preorderEnabled', false)
      const preorderFutureMaxDays = get(takeAwayConfig, 'preorderFutureMaxDays', 7)
      // Fechas iniciales y finales
      const today = new Date()
      const maxDate = new Date()
      const minDate = new Date()

      // Fecha mínima
      minDate.setDate(today.getDate() - 90)
      // Fecha máxima
      if (preorderEnabled) {
        maxDate.setDate(today.getDate() + preorderFutureMaxDays)
      }

      return {
        color: 'secondary',
        'first-day-of-week': 1,
        max: formatDate(maxDate, 'YYYY-MM-DD'),
        // min: formatDate(minDate, 'YYYY-MM-DD'),
        'no-title': true,
        range: true,
        scrollable: true
      }
    }
  },
  mounted() {
    this.getEveryNeededData()
  },
  watch: {
    // Comprobamos si el usuario tiene menu "takeAway"
    takeAwayHasMenu: {
      async handler(value) {
        if (value === false) {
          this.showDialogInitialTakeAwaySetup()
        }
      },
      immediate: true
    },
    // Cada vez que la fecha cambia
    dates: {
      async handler(value) {
        if (!isNil(value) && value.length === 2) {
          // Obtenemos items
          this.items = await this.getTakeAwayOrders()
          // Ocultamos datepicker
          this.showDatePicker = false
        }
      },
      immediate: true
    }
  },
  methods: {
    /**
     * Cuando pulsamos sobre un item del listado
     *
     * @param {string} id - UID pedido
     */
    handleClickItem(id) {
      if (id) {
        this.routerPushTo({
          name: 'takeAwayOrderDetail',
          params: { id }
        })
      }
    },
    /**
     * Obtenemos y seteamos todos los elementos necesarios
     * para la carga inicial del componente
     */
    async getEveryNeededData() {
      // Inicializamos la fecha
      this.dates = this.getInitialDates()
    },
    /**
     * Obtenemos las fechas iniciales
     *
     * @return {array}
     */
    getInitialDates() {
      const fromDate = new Date()
      const toDate = new Date()

      // Última semana
      fromDate.setDate(toDate.getDate() - 7)

      return [formatDate(fromDate, 'YYYY-MM-DD'), formatDate(toDate, 'YYYY-MM-DD')]
    },
    /**
     * Obtengo los pedidos indicados
     *
     * @param {object} options
     */
    async getTakeAwayOrders() {
      if (isNil(this.dates) || this.dates.length === 0) {
        this.dates = this.getInitialDates()
      }

      const dateFrom = new Date(formatDate(this.dates[0], 'YYYY-MM-DD')).setHours(0, 0, 0, 0)
      const dateTo = new Date(formatDate(this.dates[1], 'YYYY-MM-DD')).setHours(23, 59, 59, 0)

      const result = await getOrdersByDateAndPlaceById(
        new Date(dateFrom),
        new Date(dateTo),
        this.placeData.id
      )

      return result
    }
  }
}
